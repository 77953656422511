/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.ant-image {
  position: relative;
  display: inline-block;
}
.ant-image-img {
  width: 100%;
  height: auto;
  vertical-align: middle;
}
.ant-image-img-placeholder {
  background-color: #f5f5f5;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTQuNSAyLjVoLTEzQS41LjUgMCAwIDAgMSAzdjEwYS41LjUgMCAwIDAgLjUuNWgxM2EuNS41IDAgMCAwIC41LS41VjNhLjUuNSAwIDAgMC0uNS0uNXpNNS4yODEgNC43NWExIDEgMCAwIDEgMCAyIDEgMSAwIDAgMSAwLTJ6bTguMDMgNi44M2EuMTI3LjEyNyAwIDAgMS0uMDgxLjAzSDIuNzY5YS4xMjUuMTI1IDAgMCAxLS4wOTYtLjIwN2wyLjY2MS0zLjE1NmEuMTI2LjEyNiAwIDAgMSAuMTc3LS4wMTZsLjAxNi4wMTZMNy4wOCAxMC4wOWwyLjQ3LTIuOTNhLjEyNi4xMjYgMCAwIDEgLjE3Ny0uMDE2bC4wMTUuMDE2IDMuNTg4IDQuMjQ0YS4xMjcuMTI3IDAgMCAxLS4wMi4xNzV6IiBmaWxsPSIjOEM4QzhDIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48L3N2Zz4=');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 30%;
}
.ant-image-mask {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
       align-items: center;
  -moz-box-pack: center;
       justify-content: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  cursor: pointer;
  opacity: 0;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.ant-image-mask-info {
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.ant-image-mask-info .anticon {
  -webkit-margin-end: 4px;
     -moz-margin-end: 4px;
          margin-inline-end: 4px;
}
.ant-image-mask:hover {
  opacity: 1;
}
.ant-image-placeholder {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
.ant-image-preview {
  pointer-events: none;
  height: 100%;
  text-align: center;
}
.ant-image-preview.ant-zoom-enter,
.ant-image-preview.ant-zoom-appear {
  -webkit-transform: none;
     -moz-transform: none;
          transform: none;
  opacity: 0;
  -webkit-animation-duration: 0.3s;
          animation-duration: 0.3s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.ant-image-preview-mask {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1000;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.45);
}
.ant-image-preview-mask-hidden {
  display: none;
}
.ant-image-preview-wrap {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
  outline: 0;
}
.ant-image-preview-body {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
}
.ant-image-preview-img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  -webkit-transform: scale3d(1, 1, 1);
     -moz-transform: scale3d(1, 1, 1);
          transform: scale3d(1, 1, 1);
  cursor: -webkit-grab;
  cursor: -moz-grab;
  cursor: grab;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -moz-transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -moz-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -moz-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  pointer-events: auto;
}
.ant-image-preview-img-wrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  -moz-transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -moz-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s, -moz-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1) 0s;
}
.ant-image-preview-img-wrapper::before {
  display: inline-block;
  width: 1px;
  height: 50%;
  margin-right: -1px;
  content: '';
}
.ant-image-preview-moving .ant-image-preview-img {
  cursor: -webkit-grabbing;
  cursor: -moz-grabbing;
  cursor: grabbing;
}
.ant-image-preview-moving .ant-image-preview-img-wrapper {
  -webkit-transition-duration: 0s;
     -moz-transition-duration: 0s;
          transition-duration: 0s;
}
.ant-image-preview-wrap {
  z-index: 1080;
}
.ant-image-preview-operations-wrapper {
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1081;
  width: 100%;
}
.ant-image-preview-operations {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  -webkit-font-feature-settings: 'tnum', "tnum";
     -moz-font-feature-settings: 'tnum', "tnum";
          font-feature-settings: 'tnum', "tnum";
  display: -moz-box;
  display: flex;
  -moz-box-orient: horizontal;
  -moz-box-direction: reverse;
       flex-direction: row-reverse;
  -moz-box-align: center;
       align-items: center;
  color: rgba(255, 255, 255, 0.85);
  list-style: none;
  background: rgba(0, 0, 0, 0.1);
  pointer-events: auto;
}
.ant-image-preview-operations-operation {
  margin-left: 12px;
  padding: 12px;
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
}
.ant-image-preview-operations-operation:hover {
  background: rgba(0, 0, 0, 0.2);
}
.ant-image-preview-operations-operation-disabled {
  color: rgba(255, 255, 255, 0.25);
  pointer-events: none;
}
.ant-image-preview-operations-operation:last-of-type {
  margin-left: 0;
}
.ant-image-preview-operations-progress {
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
     -moz-transform: translateX(-50%);
          transform: translateX(-50%);
}
.ant-image-preview-operations-icon {
  font-size: 18px;
}
.ant-image-preview-switch-left,
.ant-image-preview-switch-right {
  position: fixed;
  top: 50%;
  right: 8px;
  z-index: 1081;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
       align-items: center;
  -moz-box-pack: center;
       justify-content: center;
  width: 44px;
  height: 44px;
  color: rgba(255, 255, 255, 0.85);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  -webkit-transform: translateY(-50%);
     -moz-transform: translateY(-50%);
          transform: translateY(-50%);
  cursor: pointer;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  pointer-events: auto;
}
.ant-image-preview-switch-left:hover,
.ant-image-preview-switch-right:hover {
  background: rgba(0, 0, 0, 0.2);
}
.ant-image-preview-switch-left-disabled,
.ant-image-preview-switch-right-disabled,
.ant-image-preview-switch-left-disabled:hover,
.ant-image-preview-switch-right-disabled:hover {
  color: rgba(255, 255, 255, 0.25);
  background: rgba(0, 0, 0, 0.1);
  cursor: not-allowed;
}
.ant-image-preview-switch-left-disabled > .anticon,
.ant-image-preview-switch-right-disabled > .anticon,
.ant-image-preview-switch-left-disabled:hover > .anticon,
.ant-image-preview-switch-right-disabled:hover > .anticon {
  cursor: not-allowed;
}
.ant-image-preview-switch-left > .anticon,
.ant-image-preview-switch-right > .anticon {
  font-size: 18px;
}
.ant-image-preview-switch-left {
  left: 8px;
}
.ant-image-preview-switch-right {
  right: 8px;
}
.ant-checkbox .ant-checkbox-inner {
  border-radius: 2px;
}
.background-gost {
  background-color: transparent !important;
  min-width: 300px;
}
.background-gost .ant-card-head {
  text-align: center;
}
.background-gost-bordered {
  background-color: transparent !important;
  border: 1px solid #cac6c6 !important;
  border-radius: 7px;
  padding: 1.5em !important;
}
@media only screen and (max-width: 480px) {
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

.zpMT5l0_ .KFD0AbF_ {
  border-radius: 2px;
}
.X5zpL_2b {
  background-color: transparent !important;
  min-width: 300px;
}
.X5zpL_2b .PRBI_nbT {
  text-align: center;
}
.bkgTcodk {
  background-color: transparent !important;
  border: 1px solid #cac6c6 !important;
  border-radius: 7px;
  padding: 1.5em !important;
}
@media only screen and (max-width: 480px) {
  .__21xJTjFi {
    width: 100% !important;
  }
}
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
  src: url(https://fonts.gstatic.com/s/opensans/v40/memSYaGs126MiZpBA-UvWbX2vVnXBbObj2OVZyOOSr4dVJWUgsjZ0B4gaVc.ttf) format('truetype');
}
html,
body {
  padding: 0px !important;
  margin: 0px !important;
  font-family: "Open Sans";
}
a {
  color: inherit;
  text-decoration: none;
}
* {
  -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
          box-sizing: border-box;
}
.CHtraS_h {
  width: 100%;
  height: 100vh;
  position: relative;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  -moz-box-align: center;
       align-items: center;
  display: -moz-box;
  display: flex;
  overflow: auto;
  z-index: 1;
  background-color: #f2f2f2;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.CHtraS_h .uM_xw6tO {
  top: 80px;
  position: absolute;
  max-width: 680px;
  width: 100%;
}
.CHtraS_h .uM_xw6tO .lRfvb5T4 {
  position: relative;
  border-top-left-radius: 8px;
  background-color: #fff;
}
.CHtraS_h .uM_xw6tO .lRfvb5T4 .__3TvoMOZl {
  width: 100%;
  top: -50px;
  padding: 10px;
  border-radius: 10px;
  position: relative;
  -moz-box-align: center;
       align-items: center;
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
}
.CHtraS_h .uM_xw6tO .lRfvb5T4 .__3TvoMOZl .H__vTtMU {
  width: 120px;
  position: relative;
  height: 120px;
  background-color: #fff;
  border-radius: 50%;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
       align-items: center;
  -moz-box-pack: center;
       justify-content: center;
  padding: 10px;
}
.CHtraS_h .uM_xw6tO .lRfvb5T4 .__3TvoMOZl .H__vTtMU .X9NIg2ns {
  width: 90px !important;
  height: 90px !important;
  background-image: url("/logoGreen.png");
  background-size: contain;
  background-repeat: no-repeat;
}
.CHtraS_h .uM_xw6tO .lRfvb5T4 .__3TvoMOZl .kepNbCa_ {
  color: #00599b;
  font-weight: bold;
  margin: 5px 0px;
}
.CHtraS_h .uM_xw6tO .lRfvb5T4 .__3TvoMOZl h1 {
  color: #00599b;
  font-weight: bold;
  font-size: 26px;
  margin: 5px 0px;
}
.CHtraS_h .uM_xw6tO .lRfvb5T4 .__3TvoMOZl h2 {
  color: #00599b;
  font-weight: bold;
  font-size: 22px;
  margin: 5px 0px;
}
.CHtraS_h .uM_xw6tO .lRfvb5T4 .__3TvoMOZl h3 {
  font-weight: bold;
  font-size: 16px;
  margin: 5px 0px;
}
.CHtraS_h .uM_xw6tO .lRfvb5T4 .__3TvoMOZl .nfpvdY5y {
  width: 100%;
  max-width: 280px;
}
.CHtraS_h .uM_xw6tO .lRfvb5T4 .__3TvoMOZl .zB5FVTMN {
  width: 100%;
  max-width: 320px;
  margin: 15px 0px;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
       align-items: center;
  -moz-box-pack: center;
       justify-content: center;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
}
.CHtraS_h .uM_xw6tO .v5KDtP6R {
  width: 100%;
  padding: 10px 5px;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
       align-items: center;
  -moz-box-pack: center;
       justify-content: center;
  flex-wrap: wrap;
  color: #fff;
  border-top: 1px solid #ddd;
  background-color: #fff;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}
.CHtraS_h .uM_xw6tO .v5KDtP6R .kaHrNXls {
  width: 1px;
  height: 50%;
  background-color: #888;
  margin: 0px 10px;
}
.CHtraS_h .uM_xw6tO .v5KDtP6R .__4na1TP7O {
  font-size: 16px;
  display: -moz-box;
  display: flex;
  height: 100%;
  -moz-box-align: center;
       align-items: center;
  color: #888 !important;
}
.CHtraS_h .uM_xw6tO .v5KDtP6R .__4na1TP7O i {
  margin-right: 5px;
}
.CHtraS_h .uM_xw6tO .v5KDtP6R .LZYsarVG {
  font-size: 16px;
  display: -moz-box;
  display: flex;
  height: 100%;
  -moz-box-align: center;
       align-items: center;
  color: #4d8dbb;
}
.CHtraS_h .uM_xw6tO .v5KDtP6R .LZYsarVG a {
  color: #4d8dbb;
  text-decoration: none;
}
.CHtraS_h .uM_xw6tO .v5KDtP6R .LZYsarVG i {
  margin-right: 5px;
}
.CHtraS_h .uM_xw6tO .v5KDtP6R .__6C6b2ul_ {
  font-size: 16px;
  display: -moz-box;
  display: flex;
  height: 100%;
  -moz-box-align: center;
       align-items: center;
  color: #8eb53e;
}
.CHtraS_h .uM_xw6tO .v5KDtP6R .__6C6b2ul_ a {
  color: #8eb53e;
  text-decoration: none;
}
.CHtraS_h .uM_xw6tO .v5KDtP6R .__6C6b2ul_ img {
  height: 20px;
  margin-right: 5px;
}
.__4QFs6_nS {
  width: 100%;
  margin: 0px 2px;
}
.__4QFs6_nS .YWDkAgg_ {
  display: -moz-box;
  display: flex;
  -moz-box-pack: center;
       justify-content: center;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
}
.__4QFs6_nS .YWDkAgg_ span {
  color: #888;
  font-size: 16px;
}
.__4QFs6_nS .YWDkAgg_ input {
  height: 40px;
  width: 100%;
  border-radius: 10px;
}
.__4QFs6_nS .fzfbKYOl {
  display: -moz-box;
  display: flex;
  -moz-box-pack: start;
       justify-content: flex-start;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
}
.__4QFs6_nS .fzfbKYOl span {
  color: #888;
  font-size: 16px;
}
.__4QFs6_nS .fzfbKYOl input {
  height: 40px;
  width: 100%;
  border-radius: 10px;
}
.cLxSH_VU {
  margin: 30px 0px 0px 0px;
  text-align: center;
  max-width: 620px;
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  -moz-box-align: center;
       align-items: center;
}
.cLxSH_VU .Dy2v6vvU {
  width: 100%;
  height: 50px;
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  -moz-box-align: center;
       align-items: center;
  -moz-box-pack: center;
       justify-content: center;
}
.cLxSH_VU .Dy2v6vvU button {
  background-color: #8eb53e;
  border: none;
  outline: none;
  width: 150px;
  border-radius: 5px;
}
.cLxSH_VU .ao2XSwKQ {
  width: 100%;
  height: 50px;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
       align-items: center;
  -moz-box-pack: center;
       justify-content: center;
}
.cLxSH_VU .ao2XSwKQ button {
  border: 1px solid #4d8dbb !important;
  outline: none;
  width: 150px;
  border-radius: 5px;
  color: #4d8dbb !important;
}
.cLxSH_VU a {
  border-bottom: 1px solid #4d8dbb;
  padding-bottom: 2px;
}
.CkVw_Ddg {
  width: 90%;
  margin-top: 20px;
  font-weight: bold;
  color: "#888";
}
/* Common scss */
.IkW_wBTl {
  width: 220px;
  height: 40px;
  display: -moz-box;
  display: flex;
  -moz-box-align: center;
       align-items: center;
  -moz-box-pack: center;
       justify-content: center;
  border-radius: 8px;
  color: #fff;
  background-color: #8eb53e;
}
.s3s7SWO4 {
  width: 100%;
  margin-bottom: 10px;
}
.s3s7SWO4 .M2oxi_Q0 {
  width: 100%;
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
}
.s3s7SWO4 .M2oxi_Q0 span {
  text-align: justify;
}
.s3s7SWO4 .M2oxi_Q0 input {
  width: 100%;
  padding-left: 8px;
  outline: none;
  border-radius: 8px;
  border: 1px solid #4d8dbb;
  height: 50px;
  background-color: #fff;
}
.s3s7SWO4 .M2oxi_Q0 input:focus {
  border: 1px solid #00599b;
  opacity: 0.9;
  color: #000;
}
.s3s7SWO4 .M2oxi_Q0 input:hover {
  cursor: text;
}
._TiWTB35 {
  cursor: pointer;
  margin: 5px 0px;
}
.I_EQ8I58 {
  display: -moz-box;
  display: flex;
  -moz-box-orient: vertical;
  -moz-box-direction: normal;
       flex-direction: column;
  padding: 25px 0px;
}
.I_EQ8I58 .__5ZuueJQ1 {
  height: 40px;
  border-radius: 7px !important;
  margin-bottom: 10px;
  border: 1px solid #4d8dbb !important;
  outline: none;
  width: 280px;
  background-color: transparent;
  border-radius: 5px;
  color: #4d8dbb !important;
  cursor: pointer;
}
.I_EQ8I58 .__5ZuueJQ1:hover {
  background-color: #4d8dbb;
  color: #fff !important;
}

